<template>
  <div v-if="defaultStore.footerSubmenu" class="submenu is-flex">
    <div v-for="(link, index) in defaultStore.footerSubmenu.links" :key="index">
      <DefaultLink
        :to="link.url.path ? link.url.path : null"
        class="has-text-white is-size-7"
      >
        {{ link.label }}
      </DefaultLink>
    </div>
  </div>
</template>

<script  setup lang="ts">
import { useDefaultStore } from '~/stores/defaultStore'

const defaultStore = useDefaultStore()
</script>

<style scoped lang="scss">
.submenu {
  justify-content: space-between;
}
</style>
